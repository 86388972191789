<script lang="ts" setup>
import { formatNumberWithUnit } from '~/utils/number'

const props = withDefaults(defineProps<{
  min?: string | number
  max?: string | number
  skuPrick?: string | number
  unit: string
}>(), {
  unit: '$',
})
const min = Number.parseFloat(props.min ? String(props.min) : '')
const max = Number.parseFloat(props.max ? String(props.max) : '')
const skuPrick = Number.parseFloat(props.skuPrick ? String(props.skuPrick) : '')
</script>

<template>
  <!-- 取min_sku_price - max_sku_price，若最大最小值都为0，取sku_price -->
  <span class="items-center gap-4 text-18 text-[--color-text-1] font-dinpro600 uno-layer-components:inline-flex">
    <span v-if="min">{{ unit }}{{ formatNumberWithUnit(min, 2) }}</span>
    <span v-if="min && max">-</span>
    <span v-if="max">{{ unit }}{{ formatNumberWithUnit(max, 2) }}</span>
    <span v-if="!max && !min">{{ unit }}{{ formatNumberWithUnit(skuPrick, 2) }}</span>
  </span>
</template>
