type LocaleKey = 'Cn' | 'En'

interface UseI18nKeys {
  <T extends string>(pre: T): ComputedRef<`${T}${LocaleKey}`>
  <T extends string, U extends string>(pre: T, suf: U): ComputedRef<`${T}${LocaleKey}${U}`>
}

/**
 * @example useI18nKeys('categoryName') => categoryNameCnEn
 * @example useI18nKeys('categoryName', 'Level1') => categoryNameEnLevel1
 */
export const useI18nKeys = ((pre, suf) => {
  const { locale } = useI18n()

  return computed(() => {
    let loc: LocaleKey = 'Cn'
    switch (locale.value) {
      case 'en':
        loc = 'En'
        break
    }
    return `${pre}${loc}${suf || ''}`
  })
}) as UseI18nKeys
